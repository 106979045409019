<template>
  <div id="app">
    <Top/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
  import Top from '@/components/top'
  import Footer from '@/components/footer'
  export default{
    components:{
      Top,
      Footer
    },
    data() {
      return {
         header:{
          keywords:'',
          description:'',
          og_title:'',
          og_description:''
         }
      }
    },
    mounted() {
      this.getSeo()
    },
    methods: {
     async getSeo(){
        let res = await this.$request.get('seo/getPaginate')
        if (res.data.code===0) {
           this.header=res.data.data.data[0]
           console.log(this.header);
        }
      }
    },
    metaInfo() {
      return {
        meta: [
          {
            name: 'keywords',
            content: this.header.keywords,
          },
          {
            name: 'description',
            content: this.header.description,
          },
          {
            property: 'og:title',
            content: this.header.og_title,
          },
          {
            property: 'og:description',
            content: this.header.og_description,
          },
        ],
      }
    }
  }
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
Footer{
  position: fixed;
  bottom: 0;
}
</style>
