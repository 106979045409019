import Vue from 'vue'
import App from './App.vue'
import router from '@/libs/router.js'
import store from './store'
import request from '@/libs/request.js'
import '@/assets/css/font.css'
import '@/assets/css/main.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

// 使用swiper插件
Vue.use(VueAwesomeSwiper)

//使用vue
import VueMeta from 'vue-meta';

Vue.use(VueMeta);

// 全局
import * as util from "./libs/util";
Vue.prototype.$util = util

// 全局本地存储类
import { storage } from "./libs/storage";
Vue.prototype.$storage = storage;

Vue.prototype.$request = request

//去除存在数据库的富文本产生的p标签
Vue.prototype.$deTag=function (str) {
    return str.replace(/<[^>]*>/g, '');
}

// Vue.use(Main)
// Vue.use(Font)
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
